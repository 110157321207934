import React, { useContext, useState } from 'react';
import { IAActionTypes, IAContext, viewEnum } from '../../../containers/ImpactAssessmentV2/ImpactAssessmentV2';
import { KadaBtn, KadaLoadingBar, KadaStepper, kButtonVariants, kLoadingBarVariants } from 'kada-component-library';
import SelectTarget from './SelectTarget';
import SetDetails from './SetDetails';
import axiosCerebrum from '../../../axios-cerebrum';
import useAlert from '../../../hooks/useAlert';

const Config = props => {

  const {
    // history,
    state,
    dispatch,
  } = useContext(IAContext);

  // eslint-disable-next-line
  const [activeStep, setActiveStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const [config, setConfig] = useState({
    target_object_id: state.targetObject?.id,
  })

  const {
    sendAlert
  } = useAlert({})

  const steps = [
    'Select Target',
    'Add Assessment details'
  ]

  const checkNextBtnDisabled = () => {
    if (submitting) return true;
    if (activeStep === 0) {
      return !config.assessment_level || !config.assessment_direction;
    }
    if (activeStep === 1) {
      return !config.assessment_limit || !config.expansion_limit;
    }
    return false;
  }

  const onRun = () => {
    setSubmitting(true);
    axiosCerebrum
      .post(
        '/api/iaconfigs', {
        ...config,
        assessment_limit: Number(config.assessment_limit).valueOf(),
        expansion_limit: Number(config.expansion_limit).valueOf(),
      }
      )
      .then(res => {
        setTimeout(() => {
          setSubmitting(false);
          dispatch({
            type: IAActionTypes.SET_TAB_STATE,
            tabState: viewEnum.history
          })
        }, 1000);
      })
      .catch(err => {
        setSubmitting(false);
        sendAlert({
          type: "error",
          message: "Failed to create assessment, please try again"
        })
        console.log(err);
      })
  }

  if (state.targetObjectLoading) {
    return (
      <div className='w-full mt-10'>
        <KadaLoadingBar variant={kLoadingBarVariants.secondary} text="Initialising setup configs" />
      </div>
    )
  }

  if (state.targetObjectError) {
    return (
      <div className='w-full mt-10'>
        <KadaLoadingBar variant={kLoadingBarVariants.error} isStatic text="Failed to load target object" />
      </div>
    )
  }

  if (!state.targetObject) return <></>

  return (
    <div>
      <h2 className='mb-6'>ASSESSMENT SETTINGS</h2>
      <KadaStepper
        steps={steps}
        currentStep={activeStep}
        onChange={e => {
          setActiveStep(e.detail.value);
        }}
      />

      {
        submitting ?
          <div className='w-full mt-10'>
            <KadaLoadingBar variant={kLoadingBarVariants.secondary} text="Submitting assessment" />
          </div>
          :
          <div className="mt-10">
            {
              activeStep === 0 &&
              <SelectTarget config={config} setConfig={setConfig} />
            }
            {
              activeStep === 1 &&
              <SetDetails config={config} setConfig={setConfig} />
            }
          </div>
      }

      <div
        className='fixed bottom-0 left-0 w-full h-20 pr-10 flex items-center justify-end gap-4 bg-(--color-base-100)'
      >
        <KadaBtn
          text={activeStep === steps.length - 1 ? 'RUN' : 'NEXT'}
          size={'l'}
          disabled={checkNextBtnDisabled()}
          onClick={() => {
            if (checkNextBtnDisabled()) return;
            if (activeStep === steps.length - 1) {
              onRun();
              return;
            }
            setActiveStep(activeStep + 1);
          }}
        />
        <KadaBtn
          text="CANCEL"
          variant={kButtonVariants.primaryOutlined}
          onClick={() => {
            dispatch({
              type: IAActionTypes.SET_TAB_STATE,
              tabState: viewEnum.history
            })
          }}
          size={'l'}
        />
      </div>
    </div>
  )
}

export default Config;
